import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { ApiService } from 'src/libs/api/api.service';

@Component({
  selector: 'fini-server-file-whitelist',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './server-file-whitelist.component.html',
  styleUrls: ['./server-file-whitelist.component.scss'],
})
export class ServerFileWhitelistComponent implements OnInit {
  constructor(
    private api: ApiService,
    private toast: HotToastService,
  ) {}

  ngOnInit(): void {}

  async filesSelected(ev: EventTarget) {
    const files = (ev as HTMLInputElement).files;
    const fileContents = [];
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const content = await file.text();
        fileContents.push({
          name: file.name,
          content,
        });
      }
    }

    this.api
      .post('server/file-whitelist', {
        files: fileContents,
      })
      .subscribe((res) => {
        if (res.success) {
          this.toast.success('File whitelist updated successfully');
        } else {
          this.toast.error('File whitelist update failed:' + res.message);
        }
        console.log('Updated file details:', res);
      });
  }
}
