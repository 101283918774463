import { CommonModule } from '@angular/common';
import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';
import { ApiService } from 'src/libs/api/api.service';
import { HoverCardService } from 'src/libs/hover-card/hover-card.service';
import { ApiResponse } from 'src/libs/models/ApiResponse.model';
import { ApiDownload, ApiDownloads } from 'src/libs/models/Downloads.model';
import { ApiProduct } from 'src/libs/models/Licenses.model';
import { ApiServerFeatures } from 'src/libs/models/Server.model';
import { PermissionService } from 'src/libs/permission/permission.service';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';

import { ServerFileWhitelistComponent } from './server-file-whitelist/server-file-whitelist.component';
import { ServerIPsComponent } from './server-ips/server-ips.component';

@Component({
  selector: 'fini-server',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    CustomPipesModule,
    TranslateModule,
    MatProgressBarModule,
    ServerFileWhitelistComponent,
    ServerIPsComponent,
  ],
  templateUrl: './server.component.html',
  styleUrl: './server.component.scss',
})
export class ServerComponent implements OnInit {
  constructor(
    private api: ApiService,
    private perm: PermissionService,
    private toast: HotToastService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private hovercard: HoverCardService,
    private router: Router,
  ) {}

  loading = {
    downloads: signal(true),
    features: signal(true),
  };

  downloads = signal<ApiDownload[]>([]);

  loadingIds = signal<(number | string)[]>([]);
  features = signal([]);

  purchaseAddon: WritableSignal<ApiProduct | null> = signal(null);
  cancelAddon = signal<ApiProduct | null>(null);

  downloadsAgreed = signal(localStorage.getItem('fini_download_agreed'));

  // special case
  isCoreSubActive = signal(false);

  ngOnInit(): void {
    if (this.perm.hasGlobalPermission('ADMIN')) {
      this.loading.features.set(true);
      this.api
        .post<ApiServerFeatures>('server/features')
        .pipe(map((x) => x.features))
        .subscribe((features) => {
          this.features.set(features);
          this.loading.features.set(false);
        });
    }

    this.loading.downloads.set(true);
    this.api.post<ApiDownloads>('downloads/list').subscribe((x) => {
      this.downloads.set(x.downloads);
      this.loading.downloads.set(false);
    });
  }

  downloadsAgree() {
    this.api.post('downloads/agree', {}).subscribe(() => {
      localStorage.setItem('fini_download_agreed', 'true');
    });
    this.downloadsAgreed.set('true');
  }

  downloadFile(download: ApiDownload) {
    this.loadingIds.set([...this.loadingIds(), download.id]);

    this.api
      .post(`downloads/get?download_id=${download.id}`, null, {
        responseType: 'blob',
      })
      .subscribe({
        next: (data) => {
          const file = new File([data], download.name, {
            type: 'application/zip',
          });

          const a = document.createElement('a');
          a.href = URL.createObjectURL(file);
          a.download = download.name;
          a.click();
          document.body.removeChild(a);
          this.loadingIds.update((x) => x.filter((y) => y != download.id));
        },
        error: (err) => {
          if (err.status == 500) {
            console.log('subscription error', err);
          }

          this.loadingIds.update((x) => x.filter((y) => y != download.id));

          if (err.response) {
            this.toast.error('Downloading error: ' + err.response.message);
          }
        },
      });
  }

  saveFeatures() {
    const features = this.features();
    this.loading.features.set(true);
    this.api
      .post<ApiResponse>('server/set-features', {
        features,
      })
      .subscribe((result) => {
        if (result.success) {
          this.toast.success('Server features updated!');
        } else {
          this.toast.error('Failed to change features: ' + result.message);
        }
        this.loading.features.set(false);
      });
  }

  unauthorised_show() {
    this.hovercard.show('unauthorised', {
      message: this.translate.instant('permissions.unauthorised-details'),
    });
  }
  unauthorised_hide() {
    this.hovercard.hide();
  }
}
