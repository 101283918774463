import { Injectable, isDevMode } from '@angular/core';
import { ApiService } from '../api/api.service';
import { StateService } from '../state/state.service';
import {
  ApiFiniNotifications,
  FiniNotification,
} from '../models/Notifications.model';
import { HotToastService } from '@ngneat/hot-toast';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    _notificationInterval?: number;
    _notifSocket?: WebSocket;
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(
    private api: ApiService,
    private state: StateService,
    private toast: HotToastService,
  ) {}

  realtime() {
    this.fetchNotifications();

    let prev = this.state.get<FiniNotification[]>('notifications');
    if (!window._notificationInterval) {
      window._notificationInterval = window.setInterval(() => {
        const current = this.state.get<FiniNotification[]>('notifications');
        if (JSON.stringify(prev) !== JSON.stringify(current)) {
          window.dispatchEvent(
            new CustomEvent('notifications:update', { detail: current }),
          );
        }
        prev = current;
      }, 500);
    }
    window.dispatchEvent(
      new CustomEvent('notifications:update', { detail: prev }),
    );

    // Handle visibility change
    // If the user is not on the page, disconnect the websocket
    // If the user is on the page, reconnect the websocket
    // Note: this bypasses the ReconnectHandler as it closes the socket cleanly
    if (!isDevMode()) {
      window.addEventListener('visibilitychange', () => {
        if (document.visibilityState == 'visible') {
          this.startFetchNotifications();
        } else {
          this.log('Closing socket, visibility change');
          this.disconnect();
        }
      });
    }
  }

  read(id: number) {
    const notifications = this.state.get<FiniNotification[]>('notifications');

    if (notifications.find((n) => n.id === id)?.status != 'read') {
      this.api.put('notifications/read', { id: id }).subscribe((res) => {
        if (!res.success) {
          return;
        }

        this.state.set({
          notifications: notifications.map((n) => {
            if (n.id === id) {
              return { ...n, status: 'read' };
            }
            return n;
          }),
        });
      });
    }
  }

  readAll() {
    this.api.put('notifications/read-all').subscribe({
      next: (res) => {
        if (!res.success) {
          this.toast.error(res.message);
          return;
        }

        this.fetchNotifications();
      },
      error: (err) => {
        this.toast.error('Server error: ' + err.error.message);
      },
    });
  }

  fetchNotifications() {
    this.api
      .get<ApiFiniNotifications>('notifications/list')
      .subscribe((res) => {
        if (!res.success) {
          return;
        }
        this.state.set({ notifications: res.notifications });
      });
    this.startFetchNotifications();
  }

  private disconnect() {
    if (window._notifSocket) {
      window._notifSocket.close();
      window._notifSocket = null;
    }
  }

  private startFetchNotifications() {
    let sock: WebSocket = null;
    if (window._notifSocket) {
      sock = window._notifSocket;
    }

    if (!sock || !sock.OPEN) {
      this.log('Starting notifications service');
      sock = new WebSocket(
        environment.nsBase + '?user_id=' + this.state.get('user')?.id,
      );
      window._notifSocket = sock;
    }
    window._notifSocket.addEventListener('open', this.HandleOpen.bind(this));
    window._notifSocket.addEventListener('close', this.HandleClose.bind(this));
    window._notifSocket.addEventListener(
      'message',
      this.HandleMessage.bind(this),
    );
    window._notifSocket.addEventListener('error', this.HandleError.bind(this));
    return sock;
  }

  private HandleError(event: Event) {
    // if (this.backoff * 2 < this.maxBackoff) {
    //   this.toast.error(
    //     `Reconnecting to Notifications in ${(this.backoff * 2) / 1000}s`
    //   );
    // }
    if (!this.reconnecting) {
      this.reconnectHandler();
    }
  }

  private HandleClose(event: CloseEvent) {
    if (event.code !== 1000 && !event.wasClean) {
      this.log('Socket closed unexpectedly: ', event);

      if (!this.reconnecting) {
        this.reconnectHandler();
      }
    }
  }

  private HandleMessage(event: MessageEvent) {
    const data = JSON.parse(event.data);
    if (data.action === 'notification') {
      this.state.set({
        notifications: [data.notification, ...this.state.get('notifications')],
      });
    }
    if (data.action === 'security_check') {
      window.location.reload();
    }
    if (data.action === 'counts') {
      window.dispatchEvent(
        new CustomEvent('socket:counts', { detail: data.data }),
      );
    }
  }

  private HandleOpen(event: Event) {
    this.log('Socket opened');
    window._notifSocket.send(
      JSON.stringify({
        action: 'authenticate',
        token: this.state.get('token'),
      }),
    );
    if (this.state.get('user')?.['permissions'].includes('ADMIN')) {
      window._notifSocket.send(
        JSON.stringify({
          action: 'authenticate_admin',
          token: this.state.get('token'),
        }),
      );
    }
  }

  // Reconnection logic
  private reconnect() {
    if (window._notifSocket) {
      window._notifSocket.close();
      window._notifSocket = null;
    }
    this.startFetchNotifications();
  }

  // Exponential backoff
  private backoff = 1000;
  private maxBackoff = 30000;
  private reconnectTimeout: any = null;
  private reconnecting = false;
  private reconnectCount = 0;
  private reconnectMaxCount = 5;

  /**
   * Handles the reconnection logic for the notifications service.
   * If the reconnect count exceeds the maximum count, the reconnection is stopped.
   * Otherwise, it increments the reconnect count, sets the reconnecting flag to true,
   * and schedules a reconnection using a timeout.
   * The backoff time is increased exponentially until it reaches the maximum backoff time.
   */
  private reconnectHandler() {
    if (this.reconnectCount >= this.reconnectMaxCount) {
      this.log(
        'Reconnection failed after',
        this.reconnectMaxCount,
        'attempts.',
      );
      this.toast.error(
        'Failed to reconnect to the notifications server. Please refresh the page.',
      );
      this.reconnecting = false;
      return;
    }
    this.reconnectCount++;
    this.reconnecting = true;
    this.reconnectTimeout = setTimeout(() => {
      this.log('Reconnecting attempt', this.reconnectCount);
      this.reconnect();
      setTimeout(() => {
        if (this.reconnecting) {
          clearTimeout(this.reconnectTimeout);
          this.backoff = Math.min(this.backoff * 2, this.maxBackoff);

          if (
            !window._notifSocket ||
            window._notifSocket.readyState !== window._notifSocket.OPEN
          ) {
            this.reconnectHandler();
            return;
          }

          this.log('Reconnection successful.');
          this.reconnecting = false;
          this.reconnectCount = 0;
          this.backoff = 1000;
        }
      }, 1000);
    }, this.backoff);

    this.log('Next reconnection attempt in', this.backoff, 'ms');
  }

  private log(...args: any[]) {
    // console.log(`%c[NotificationsService]`, 'color: purple', ...args);
  }
}
