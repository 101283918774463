import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HoverCardComponent } from './hover-card.component';
import { CustomPipesModule } from '../pipes/custom-pipes.module';
import { HcBanDetailsComponent } from './hc-ban-details/hc-ban-details.component';

@NgModule({
  declarations: [HoverCardComponent],
  imports: [CommonModule],
  exports: [HoverCardComponent],
})
export class HoverCardModule {}
