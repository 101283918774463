import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/libs/auth/admin.guard';
import { AuthGuard } from 'src/libs/auth/auth.guard';
import { ServerGuard } from 'src/libs/auth/server.guard';
import { PermissionGuard } from 'src/libs/permission/permission.guard';

import { NotFoundComponent } from './not-found/not-found.component';
import { ServerComponent } from './server/server.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: HomepageComponent,
  // },
  {
    path: '',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, ServerGuard],
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'server',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    component: ServerComponent,
  },
  {
    path: 'configs',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () =>
      import('./configs/configs.module').then((m) => m.ConfigsModule),
  },
  {
    path: 'logs',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () => import('./logs/logs.module').then((m) => m.LogsModule),
  },
  {
    path: 'identities',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () =>
      import('./identities/identities.module').then((m) => m.IdentitiesModule),
  },
  {
    path: 'apollo',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () =>
      import('./apollo/apollo.module').then((m) => m.ApolloModule),
  },
  {
    path: 'whitelist',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () =>
      import('./whitelist/whitelist.module').then((m) => m.WhitelistModule),
  },
  {
    path: 'webhooks',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () =>
      import('./webhooks/webhooks.module').then((m) => m.WebhooksModule),
  },
  {
    path: 'triggers',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () =>
      import('./triggers/triggers.module').then((m) => m.TriggersModule),
  },
  {
    path: 'threats',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () =>
      import('./threats/threats.module').then((m) => m.ThreatsModule),
  },
  {
    path: 'docs',
    canActivate: [AuthGuard, ServerGuard, PermissionGuard],
    loadChildren: () => import('./docs/docs.module').then((m) => m.DocsModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard, ServerGuard],
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'authenticate',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule,
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'utils',
    loadChildren: () => {
      return import('./utils/utils.module').then((m) => m.UtilsModule);
    },
  },
  {
    path: 'setup',
    canActivate: [AuthGuard, ServerGuard],
    loadChildren: () =>
      import('./setup/setup.module').then((m) => m.SetupModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
