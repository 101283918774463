<fini-header *ngIf="loggedInPage$ | async"></fini-header>
<fini-nav-menu
  *ngIf="(loggedInPage$ | async) && ('hasServers' | state | async)"
></fini-nav-menu>
<fini-banners></fini-banners>
<div class="container-xl page-content page-{{ currentRoute$ | async }}">
  <div class="server-notifications">
    <fini-notification
      *ngFor="let noti of notifications"
      [message]="noti.message"
      [type]="noti.type"
    ></fini-notification>
  </div>
  <router-outlet
    *ngIf="(guard.loading$ | async) === false; else loading"
  ></router-outlet>
</div>
<ng-container teleportOutlet="modals"></ng-container>
<ng-template #loading>
  <div class="loading mt-5 text-center" *ngIf="guard.loading$ | async">
    <div class="spinner-border"></div>
  </div>
</ng-template>
@if (loggedInPage$ | async) {
  <fini-security-check />
  <fini-guides />
  <fini-hovercard-outlet></fini-hovercard-outlet>
  @if (!localhost) {
    <fini-onboarding />
  }
}
