import { CommonModule } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ApiThreat } from 'src/libs/models/Threats.model';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';

@Component({
  selector: 'fini-unauthorised',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './unauthorised.component.html',
  styleUrl: './unauthorised.component.scss',
})
export class UnauthorisedComponent {
  @Input() message: string;
}
