import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityCheckComponent } from './security-check.component';
import { ModalModule } from '../modal/modal.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SecurityCheckComponent],
  imports: [CommonModule, FormsModule, ModalModule],
  exports: [SecurityCheckComponent],
})
export class SecurityCheckModule {}
