<div *ngIf="config() as config" class="config-tooltip">
  <p class="config-name">{{ config.name_friendly }}</p>
  <a href="/docs/configs/{{ config.name }}" target="_blank" class="open-docs">
    <div class="material-symbols-outlined">open_in_new</div>
  </a>
  <p class="config-description">
    {{ configText() }}
  </p>
  <div *ngIf="configDetectionBadges() as badges" class="badges">
    <span *ngFor="let badge of badges" class="fini-badge detection">
      {{ badge }}
    </span>
  </div>
</div>
