import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StateService } from '../state/state.service';
import * as CryptoJS from 'crypto-js';
import { ApiServer } from '../models/Profile.model';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private state: StateService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.url.includes(environment.apiBase)) {
      return next.handle(req);
    }

    let headers = req.headers;

    const securityHeaders = this.addSecurityHeaders(req.url);
    for (const [key, value] of Object.entries(securityHeaders)) {
      headers = headers.set(key, value);
    }

    const sessionHeaders = this.addSessionHeaders();
    for (const [key, value] of Object.entries(sessionHeaders)) {
      headers = headers.set(key, value);
    }

    const clonedRequest = req.clone({ headers });

    return next.handle(clonedRequest);
  }

  private addSessionHeaders() {
    const headers: Record<string, string> = {};

    const token = this.state.get<string>('token');
    if (token) {
      headers['X-Auth-Token'] = token;
    }

    const server = this.state.get<ApiServer>('selectedServer');
    if (server) {
      headers['X-Server-Id'] = server.id?.toString();
    }

    return headers;
  }

  private hMacKey = [
    102, 105, 110, 105, 45, 106, 84, 73, 55, 107, 100, 99, 111, 49, 55, 109, 69,
    70, 119, 115, 113,
  ];

  private addSecurityHeaders(url: string) {
    const now = Math.floor(Date.now() / 1000).toString();
    const urlPath = new URL(url).pathname;

    const hash = CryptoJS.HmacSHA256(
      urlPath + now,
      String.fromCharCode.apply(null, this.hMacKey)
    );

    const headers: Record<string, string> = {};

    headers['X-Fini-Verify'] = encodeURIComponent(
      hash.toString(CryptoJS.enc.Base64)
    );

    headers['X-Fini-RequestTime'] = now;
    return headers;
  }
}
