<div class="ban-details">
  <ng-container *ngIf="!loading(); else loadingView">
    <div class="info">
      <p><b>ID:</b> {{ ban?.id }}</p>
      <p>
        <b>UID: </b>
        <span [class.long-boi]="ban?.uid.length > 30">{{ ban?.uid }}</span>
      </p>
      <p>
        <b>{{ 'threats.severity.name' | translate }}: </b>
        {{ 'threats.severity.' + ban?.severity | translate }}
      </p>
      <p>
        <b>{{ 'threats.date' | translate }}:</b>
        {{ ban?.time * 1000 | date : 'yyyy/MM/dd HH:mm' }}
      </p>
      <p>
        <b>{{ 'threats.user' | translate }}:</b>
        {{ ban?.created_by == 0 ? 'System Auto-ban' : ban?.created_by_name }}
      </p>
      <p>
        <b>{{ 'threats.server' | translate }}:</b>
        {{ ban?.server_id == 0 ? 'Global' : 'Local' }}
        {{ ban?.server_name ? '/ ' + ban?.server_name : '' }}
      </p>
      <p>
        <b>{{ 'threats.joined' | translate }}:</b>
        {{ ban?.servers ? ban?.servers : 'N/A' }}
      </p>
    </div>

    <div>
      <div
        class="faux-textarea"
        spellcheck="false"
        [innerHTML]="ban?.reason"
      ></div>
    </div>
  </ng-container>

  <ng-template #loadingView>
    <div class="info loading">
      <p class="d-flex flex-row">
        <b>ID:</b>
        <span class="placeholder"></span>
      </p>
      <p class="d-flex flex-row">
        <b>UID: </b>
        <span class="placeholder"></span>
      </p>
      <p class="d-flex flex-row">
        <b>{{ 'threats.severity.name' | translate }}: </b>
        <span class="placeholder"></span>
      </p>
      <p class="d-flex flex-row">
        <b>{{ 'threats.date' | translate }}:</b>
        <span class="placeholder"></span>
      </p>
      <p class="d-flex flex-row">
        <b>{{ 'threats.user' | translate }}:</b>
        <span class="placeholder"></span>
      </p>
      <p class="d-flex flex-row">
        <b>{{ 'threats.server' | translate }}:</b>
        <span class="placeholder"></span>
      </p>
      <p class="d-flex flex-row">
        <b>{{ 'threats.joined' | translate }}:</b>
        <span class="placeholder"></span>
      </p>
    </div>

    <div>
      <div class="faux-textarea placeholder"></div>
    </div>
  </ng-template>
</div>
