<nav [class]="smallNav()" [class.no-transition-override]="override()">
  <div class="container-xl d-lg-flex d-none desktop">
    <ng-container *ngFor="let nav of navs">
      <fini-tooltip
        *ngIf="!nav.condition || nav.condition('desktop')"
        [id]="nav.name | lowercase"
      >
        <a class="link" [routerLink]="nav.link">
          <i class="material-icons-outlined">{{ nav.icon }}</i>
          <span>{{ 'nav.' + (nav.name | lowercase) | translate }}</span>
        </a>
        <div content *ngIf="smallNav() !== ''">
          {{ 'nav.' + nav.name | lowercase | translate }}
        </div>
      </fini-tooltip>
    </ng-container>
  </div>
</nav>
<nav class="mob-menu d-block d-lg-none">
  <div class="mobile">
    <i
      class="material-icons-outlined"
      (click)="toggleMob(!mobOpen$.getValue())"
    >
      apps
    </i>
  </div>
  <div class="menu-shelf" *ngIf="mobOpen$ | async">
    <ng-container *ngFor="let nav of navs">
      <fini-tooltip
        [close]="true"
        *ngIf="!nav.condition || nav.condition('mobile')"
      >
        <div class="link" (click)="navigate(nav.link)">
          <i class="material-icons-outlined">{{ nav.icon }}</i>
          <span>{{ 'nav.' + (nav.name | lowercase) | translate }}</span>
        </div>
        <div content>
          {{ 'nav.' + nav.name | lowercase | translate }}
        </div>
      </fini-tooltip>
    </ng-container>
  </div>
</nav>
