<div
  class="hover-card"
  [class.hidden]="!hoverCardActive()"
  [style.top.px]="setpos.y + 30"
  [style.left.px]="setpos.x"
  [style.max-width.px]="viewport().width - setpos.x - 16"
  [style.max-height.px]="viewport().height - setpos.y - 16"
  (mouseleave)="handleHovercardHide()"
  #hovercardContent
>
  <div class="hover-card-content">
    <ng-container
      *ngComponentOutlet="content(); inputs: contentInputs()"
    ></ng-container>
  </div>
</div>
