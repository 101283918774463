<fini-modal *ngIf="showOnboarding()" (closed)="showOnboarding.set(false)">
  <ng-container *ngFor="let workflow of shownWorkflows()">
    <div class="heading p-4 text-center">
      <h3>{{ workflow.name }}</h3>
      <span>{{ workflow.description }}</span>
    </div>
    <div class="std-modal p-4">
      <div class="onboarding">
        <div class="step" *ngFor="let step of workflow.steps; let i = index">
          <div class="header">
            <span class="step-num" [class.complete]="step.completed">
              <span class="incomplete">
                {{ i + 1 }}
              </span>
              <i class="material-symbols-outlined checked"> check_circle </i>
            </span>
            <h4>{{ step.name }}</h4>
          </div>
          <p class="description">{{ step.description }}</p>
          <button
            *ngIf="!step.completed"
            class="button primary"
            (click)="handleAction(step.action)"
          >
            Take me there!
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</fini-modal>
