import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { HeaderComponent } from 'src/libs/header/header.component';
import { NavMenuComponent } from 'src/libs/nav-menu/nav-menu.component';
import { CustomPipesModule } from 'src/libs/pipes/custom-pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { HomepageComponent } from './homepage/homepage.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { environment } from 'src/environments/environment';
import { DiscordLoginProvider } from 'src/libs/oauth/providers/Discord.login-provider';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipModule } from '../libs/tooltip/tooltip.module';
import { NotificationModule } from 'src/libs/notification/notification.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SecurityCheckModule } from 'src/libs/security-check/security-check.module';
import { GuidesModule } from 'src/libs/guides/guides.module';
import { PaginationModule } from 'src/libs/pagination/pagination.module';
import { OnboardingModule } from 'src/libs/onboarding/onboarding.module';
import { ApiInterceptor } from 'src/libs/api/api.interceptor';
import { HoverCardModule } from '../libs/hover-card/hover-card.module';
import { NgxStripeModule, provideNgxStripe } from 'ngx-stripe';
import { FeatureLimiterModule } from '../libs/feature-limiter/feature-limiter.module';
import { BannersComponent } from '../libs/banners/banners.component';
import { TeleportModule } from '@ngneat/overview';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HeaderComponent,
    HomepageComponent,
    AuthenticationComponent,
    NotFoundComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CustomPipesModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TooltipModule,
    NotificationModule,
    ScrollingModule,
    SecurityCheckModule,
    GuidesModule,
    PaginationModule,
    OnboardingModule,
    HoverCardModule,
    NgxStripeModule.forRoot(),
    FeatureLimiterModule,
    BannersComponent,
    TeleportModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: DiscordLoginProvider.PROVIDER_ID,
            provider: new DiscordLoginProvider(
              environment.loginProviders.discord.clientId
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    provideHotToastConfig({
      position: 'top-center',
      theme: 'toast',
      stacking: 'depth',
    }),
    provideNgxStripe(),
  ],
})
export class AppModule {}
