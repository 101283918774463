import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';

export function enableSentry() {
  Sentry.init({
    dsn: 'https://792d7b91b1db9024e2657053809ed494@sentry.fini.dev/3',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: true,
        networkDetailAllowUrls: [
          'https://fini.dev/json/hooks/list',
          'https://fini.dev/json/hooks/files',
          'https://fini.dev/api/v3/keys/list',
          'https://fini.dev/api/v3/keys/permission-list',
        ],
        networkRequestHeaders: [
          'X-Fini-Verify',
          'X-Fini-RequestTime',
          'X-Auth-Token',
          'X-Server-Id',
        ],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/fini\.dev/],
    // Session Replay
    replaysSessionSampleRate: 0.005, // This sets the sample rate at 0.5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: `ui-${environment.release}`,
    environment: environment.production ? 'production' : 'dev', // we shuold somehow take into consideration stanging as well, so prod, stage, dev
  });
}
