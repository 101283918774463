<fini-modal *ngIf="showSecurityCheck()">
  <div class="std-modal">
    <div class="d-flex justify-content-between">
      <h2>Security Check</h2>
      <i class="material-symbols-outlined provider">{{ providerIcon() }}</i>
    </div>
    <div class="content-group">
      <ng-container *ngIf="provider() == 'password'">
        <p>For your security, please enter your password to continue.</p>

        <input
          type="password"
          class="input-text w-100"
          [ngModel]="code()"
          (ngModelChange)="code.set($event)"
          placeholder="Password"
          (keyup.enter)="check()"
        />
      </ng-container>
      <ng-container *ngIf="provider() == 'app'">
        <p>
          For your security, please enter your MFA code from your authenticator
          app to continue.
        </p>
        <input
          type="text"
          class="input-text w-100"
          [ngModel]="code()"
          (ngModelChange)="code.set($event)"
          placeholder="MFA code"
          (keyup.enter)="check()"
        />
      </ng-container>
      <ng-container *ngIf="provider() == 'email'">
        <p>
          For your security, we have emailed you a one time passcode. Please
          enter it below to continue.
        </p>
        <input
          type="text"
          class="input-text w-100"
          [ngModel]="code()"
          (ngModelChange)="code.set($event)"
          placeholder="Email code"
          (keyup.enter)="check()"
        />
      </ng-container>
    </div>
    <button class="button primary" (click)="check()">Submit</button>
  </div>
</fini-modal>
