import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuidesComponent } from './guides.component';
import { GuidedTourModule, GuidedTourService } from 'finiac-ngx-guided-tour';

@NgModule({
  declarations: [GuidesComponent],
  imports: [CommonModule, GuidedTourModule],
  providers: [GuidedTourService],
  exports: [GuidesComponent],
})
export class GuidesModule {}
